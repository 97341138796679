p.error-message {
    color: #ff0000; /* Red color for error messages */
    font-size: 14px; /* Adjust font size as needed */
    margin-top: 5px; /* Add some spacing above the error message */
  }
  

  .registration .text-light h1, .text-light h2, .text-light h3, .text-light h4, .text-light h5, .text-light h6, .text-light p, .text-light a {
    color: #54585a;
  }

  .donation-area .donation-form button {
    background-color: #2dccd3;
    margin-right: 5px;
  }

  .donation-area .donation-form button.danger {
    background-color: #fcb61c;
    margin-right: 5px;
  }